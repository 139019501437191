<template>
    <div>
        <NavigationBar/>

        <div class="fs-contact-page">
            <div class="fs-page-header">
                <h1>Επικοινωνία</h1>
            </div>
            <div class="fs-contact-map"></div>
            <div class="fs-contact-formcontainer">
              <div class="fs-contact-address-details">
                  <div class="address-details">
                    <div class="address-details-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="url(#svg-gradient)">
                        <path d="M428,973q34.78-30,69.55-60.1Q589.67,833,681.73,753c.62-.54,1.29-1,2.36-1.84q9,7.82,18,15.61Q820.25,869.32,938.38,971.9A14.88,14.88,0,0,0,940,973v1l-22.56,25.26-3.72,4.15-23.83-20.63v6.83q0,93.73,0,187.46c0,27.41-15.45,49.92-40.16,58.51-4.68,1.63-9.69,3.08-14.57,3.16-23.66.37-47.32.24-71,.29h-5.47a12.49,12.49,0,0,1,1.06-2c10.36-12,20.69-23.94,31.2-35.76a7.63,7.63,0,0,1,5.06-2.12c10.83-.2,21.66,0,32.49-.12,13-.1,21.37-8.28,21.39-21.24q.12-113.24-.13-226.45c0-2.29-2-5.12-3.93-6.77Q783.7,890.37,721.35,836.37L684.06,804c-2.85,2.42-5.63,4.75-8.36,7.12q-76.62,66.51-153.29,133a11.51,11.51,0,0,0-4.44,9.75q.21,111.23.11,222.46c0,11.9,5.86,20.26,16,22.26,3.55.7,7.3.45,11,.47,9,.06,18,0,27,.08,1.51,0,3.56.37,4.43,1.38,10.89,12.55,21.6,25.25,32.64,38.25-1.61.09-3.16.26-4.72.26q-32,0-64,0c-36.36-.07-62.2-26-62.23-62.46q-.08-93.48,0-187v-6.76l-23.76,20.44L428,974Z" transform="translate(-428 -751.12)"/><path d="M681,963.25c57.89.44,104.74,38.71,115.58,92.25,5.83,28.78,1.33,56.23-12.2,82.29-18.32,35.27-44.64,64.07-73.33,90.92-7.65,7.16-15.93,13.66-23.84,20.55-2,1.75-3.5,2.38-5.94.38-34.87-28.54-66.39-60.14-90.29-98.7-18.69-30.18-27.47-62.64-18.8-97.78,11.6-47,41.74-76.07,88.8-87.3C668.49,964.06,676.42,963.81,681,963.25Zm3,236c20.59-17.92,44.44-45.59,58.5-68.19,10.83-17.42,17.79-35.89,16.43-57-2.55-39.62-36-71.56-75.32-70.76-53.42,1.09-86.57,52.12-70.59,101.14,5.59,17.16,15.37,32,26.18,46.29C652.49,1168.27,667.73,1184,684,1199.28Z" transform="translate(-428 -751.12)"/><path d="M684.22,1108a30.11,30.11,0,0,1-30.17-29.67c-.16-16.27,13.34-30,29.73-30.19,16.19-.21,30,13.41,30.16,29.74A30.09,30.09,0,0,1,684.22,1108Z" transform="translate(-428 -751.12)"/>
                        <defs>
                          <linearGradient id="svg-gradient" x2="1" y2="1">
                            <stop offset="0%" stop-color="var(--color-primary)" />
                            <stop offset="100%" stop-color="var(--color-fourth)" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <address class="underline">
                      Πάροδος Μιχ. Λογίου 4<br>
                      Νάουσα, Ημαθία / 59200<br>
                    </address>
                  </div>
                  <div class="address-details">
                    <div class="address-details-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360.56 360.56" fill="url(#svg-gradient)">
                       <path d="M381.32,870.18c1.34-3.3,2.36-6.77,4.07-9.87,10.11-18.39,22.92-34.88,37.13-50.19a325.13,325.13,0,0,1,27-25.38c12.78-11,25.71-4.34,32.32,4.7,16.88,23.1,32.59,47,44.6,73.1a97.14,97.14,0,0,1,4.25,12,17.85,17.85,0,0,1-3.69,17.9c-4.36,4.94-9.36,9.32-14.2,13.82a126.12,126.12,0,0,1-10,8.5c-2.37,1.78-2.44,3.5-1.5,6,12.08,32.59,32.21,59,60.63,79a162.12,162.12,0,0,0,38.79,20.08c2.4.86,3.79.54,5.4-1.45q6.25-7.73,12.93-15.08a95,95,0,0,1,9.62-9.42,18.57,18.57,0,0,1,17.61-3.61c13.39,3.69,25.44,10.33,37.14,17.48,15.44,9.44,30.5,19.51,45.66,29.4a28.41,28.41,0,0,1,11.59,14.75c1.82,5.16,1.79,10.14-1.3,14.69-3.43,5-6.83,10.21-11,14.67-18.75,20.32-40.28,37.25-63.49,52.24-9.19,5.93-18.36,5.62-28.3,3.62-33-6.63-62.79-20.59-90.65-38.84-56.36-36.91-101.84-84.27-135.09-143-12.61-22.28-22.13-45.83-27-71.08-.42-2.21-.77-4.43-1.08-6.66s-.54-4.71-.8-7.07Zm336.39,188.66c-1.9-1.53-3-2.51-4.19-3.37a422.57,422.57,0,0,0-65.85-39.26c-5.79-2.77-5.73-2.7-10,2.07-7.31,8.19-14.65,16.37-22.11,24.42-3.17,3.43-7.32,4.06-11.75,3-2.06-.5-4.12-1-6.14-1.67-37.18-11.92-67.47-33.69-91.21-64.5-14.29-18.55-25.25-38.9-31-61.81-1.23-4.9-.43-9.12,3.2-12.74,1.77-1.76,3.36-3.73,5.22-5.38,7-6.22,14-12.4,21.18-18.46,2-1.7,2-3,.93-5.36a434.64,434.64,0,0,0-35-60.58c-2.61-3.78-5.37-7.45-8.11-11.25-.76.55-1.2.8-1.56,1.13A318.14,318.14,0,0,0,407.28,869a7.21,7.21,0,0,0-.81,4.52c2.94,21.39,10.24,41.43,20.21,60.4,34,64.66,83.07,115.1,145.63,152.43,23.18,13.83,47.86,24.21,74.85,28.12a8.73,8.73,0,0,0,5.8-1.33c11-7.95,22.33-15.64,32.72-24.39C696.72,1079.4,706.87,1069,717.71,1058.84Z" transform="translate(-381.32 -779.02)"/><path d="M729.71,946.91H706.14c-.81-36.08-13.28-67.5-38.92-93.15s-56.84-38.08-93-38.88c0-7.93,0-15.63,0-23.33C652.38,788.43,730.68,857,729.71,946.91Z" transform="translate(-381.32 -779.02)"/><path d="M574.26,851.18c53.14-.45,96.31,44.54,95.49,95.58H646.12c-.61-19.57-7.3-36.61-21.17-50.52s-30.87-20.74-50.69-21.34Z" transform="translate(-381.32 -779.02)"/>
                       <defs>
                          <linearGradient id="svg-gradient" x2="1" y2="1">
                            <stop offset="0%" stop-color="var(--color-primary)" />
                            <stop offset="100%" stop-color="var(--color-fourth)" />
                          </linearGradient>
                       </defs>
                      </svg>
                    </div>
                    <address class="underline">
                      2332 0 52260
                    </address>
                  </div>
                  <div class="address-details">
                    <div class="address-details-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 345.48 199.63" fill="url(#svg-gradient)">
                       <path d="M573.94,1074.27c28.34-24.29,56.69-48.58,85.48-73.27L573.9,927.69l-1.26.36c0,4.8.08,9.6,0,14.39s-2.46,7.83-6.37,8.42c-4.43.68-8.57-2.09-8.82-6.55a84.53,84.53,0,0,1,.24-16.46c2.27-16,14.8-26.61,31.15-26.63q70.86-.09,141.72,0,46.68,0,93.36,0c20,0,32.82,12.77,32.84,32.76q0,67.13,0,134.23c0,19.77-12.9,32.61-32.62,32.61q-117,0-234,0c-19.95,0-32.87-12.91-32.89-32.78,0-2.75,0-5.5,0-8.25.07-5.39,3-8.72,7.65-8.74s7.59,3.23,7.71,8.68c.09,4.71,0,9.42,0,14.12ZM585,916.76c1.53,1.42,2.31,2.19,3.15,2.91q56.12,48,112.23,96c5.06,4.33,8.35,4.27,13.51-.14l112.2-96c.82-.71,1.58-1.47,2.93-2.74Zm-.07,168.34H829.17l-86.4-74.1c-7.26,6.31-14.15,12.47-21.24,18.38a22,22,0,0,1-27.11,1.47,35.86,35.86,0,0,1-3.2-2.59L671.31,1011ZM841,927l-86.34,74L841,1075Z" transform="translate(-511.29 -901.18)"/><path d="M553.21,1027.94c-11.12,0-22.23.06-33.35,0-5.88,0-9.42-4.1-8.4-9.34.73-3.76,3.85-6.07,8.69-6.11,9.61-.07,19.23,0,28.84,0,12.49,0,25,0,37.47,0,5.19,0,8.5,2.51,9.06,6.58.69,5.06-2.85,8.84-8.6,8.88C575.68,1028,564.44,1027.94,553.21,1027.94Z" transform="translate(-511.29 -901.18)"/><path d="M564.77,974.06c7.25,0,14.49,0,21.74,0,5.65,0,9.08,3,9.07,7.73s-3.41,7.72-9.09,7.74q-21.54.09-43.09,0c-5.61,0-9.16-3.15-9.16-7.75s3.61-7.68,9.17-7.72C550.53,974,557.65,974.06,564.77,974.06Z" transform="translate(-511.29 -901.18)"/>
                      <defs>
                          <linearGradient id="svg-gradient" x2="1" y2="1">
                            <stop offset="0%" stop-color="var(--color-primary)" />
                            <stop offset="100%" stop-color="var(--color-fourth)" />
                          </linearGradient>
                       </defs>
                      </svg>
                    </div>
                    <address class="underline">
                      fydonik@gmail.com<br>
                    </address>
                  </div>
              </div>
              <div class="fs-contact-form">
                <ContactForm/>
              </div>
              
            </div>
         </div>

        
    </div>
</template>

<script>
 import ContactForm from '@/components/ContactForm.vue';
 import NavigationBar from '@/components/NavigationBar.vue';

export default {
  name: 'Contact',
   components: {   
    ContactForm,
    NavigationBar
  }
}
</script>

<style scoped>
.fs-page-header {
    width:100%;
    background-image:url("../assets/heroBackground.jpeg");
    background-size:cover;
    background-position: center center;
    background-attachment: fixed;
    padding:var(--container-padding);
}

.fs-page-header h1 {
    color: white;
}

.fs-contact-map {
  width:100%;
  height: 20rem;
  background-image:url("../assets/contactMap.jpg");
  background-size:cover;
  background-position: center center;
}

.fs-contact-formcontainer {
  padding:5rem 0;
  display:flex;
  background:url(../assets/productBackground.jpg);
  background-size:cover;
  background-position: center center;
}
.fs-contact-formcontainer > div {
  flex:auto;
}

.fs-contact-address-details {
  width:40%;
  padding:0 var(--container-padding);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.address-details {
  padding: var(--container-padding) 0;
  max-height:8rem;
  display: flex;
  align-items: center;
 background:white;
 box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
border-top:4px solid var(--color-tertiary);
    border-radius:20px;
}

.address-details-icon {
  margin-right:2rem;
  width:5rem;
  aspect-ratio:1;
  padding:1.155rem;
  display:grid;
  place-items:center;
}

.fs-contact-address-details address {

  font-style:normal;
  color:var(--text-color-primary);
}

.fs-contact-form {
  width:60%;
}

@media screen and (max-width:768px) {

.address-details { 
  margin-bottom:5rem;
}

  .fs-contact-formcontainer {
  padding:5rem;
  display:flex;
  flex-direction: column;
  }
  .fs-contact-formcontainer > div {
  width:100% !important;
  }
}

</style>